import { h, render, Fragment } from "preact";

export default function CongratsFunds() {
    return (
        <div className="relative h-screen bg-white font-inter flex items-center flex-col">
            <div className="flex flex-col justify-center items-center pt-[8rem] mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-[2rem]">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-12 text-green-500"
                >
                    <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                    />
                </svg>
                <div className="mt-[0.5rem]">
                    <p className="text-center text-[#ff7317] text-2xl font-semibold underline underline-offset-2">
                        Congratulations!
                    </p>
                    <p className="text-[#4a4a4a] text-justify text-lg mt-[0.5rem]">
                        Your loan is complete! Funds are on the
                        way!
                    </p>
                </div>
                <hr className="w-[20rem] lg:w-[30rem] h-[1px] mx-auto mt-4 bg-[#4a4a4a] border-0 rounded" />
                <div className="flex flex-col justify-center items-start w-[80%] lg:w-[60%] my-8">
                    <p className="text-justify text-[#ff7317] text-lg font-semibold">
                        When will I receive my funds?
                    </p>
                    <p className="text-[#4a4a4a] text-justify mt-[0.5rem]">
                        Funds should appear in your account the
                        next business day.
                    </p>
                    <p className="text-justify text-[#ff7317] text-lg font-semibold mt-4">
                        How much is my first payment, and when
                        is it due?
                    </p>
                    <p className="text-[#4a4a4a] text-justify mt-[0.5rem]">
                        Payment dates and amounts are detailed
                        in your Loan Agreement on the{" "}
                        <span>
                            <a
                                href="/account/loans"
                                className="text-[#ff7317] underline"
                            >
                                Loan Overview
                            </a>
                        </span>{" "}
                        page.
                    </p>
                    <p className="text-left text-[#ff7317] text-lg font-semibold mt-4">
                        What are my payoff options?
                    </p>
                    <p className="text-[#4a4a4a] text-justify mt-[0.5rem]">
                        To save money on your loan, you can make
                        extra payments or pay your loan in full
                        at any time. Doing so will reduce the
                        total interest you'll pay over the life
                        of the loan. If have questions about
                        making additional payments, please don’t
                        hesitate to{" "}
                        <span>
                            <a
                                href="/contact-us"
                                className="text-[#ff7317] underline cursor-pointer"
                            >
                                reach out
                            </a>
                        </span>
                        . We’re here to help!
                    </p>
                </div>
                <div className="w-[80%] lg:w-[60%]">
                    <p className="text-gray-500 text-xs text-justify">
                        This is an expensive form of borrowing.
                        Today Cash installment loans are
                        designed to assist you in meeting your
                        short-term financial needs and are not
                        intended to be a long-term financial
                        solution.
                    </p>
                </div>
                <div className="mt-[3rem] mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="text-center">
                        <a
                            href="/account/"
                            className="transition-colors duration-300 ease-in-out text-white bg-[#ff7317] hover:bg-[#c2410c] mt-[1rem] no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium"
                        >
                            Go to Account
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
