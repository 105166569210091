import { h, render, Fragment } from 'preact';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const faqs = [
    {
        id: 1,
        question: 'What is an installment loan?',
        answer: (
            <p>
                It's a loan that can provide you with needed extra cash before
                your next pay date! The number of payments as well as payment
                amount is pre-set when the loan is obtained, and the amount of
                the loan is usually between $100.00 and $1,500.00.
            </p>
        ),
    },
    {
        id: 2,
        question: 'Can I qualify for an installment loan?',
        answer: (
            <Fragment>
                <p className="mb-5">
                    Yes, it's easy to qualify! To be approved for a loan, you
                    only need:
                </p>
                <ul className="list-disc pl-10">
                    <li>
                        Proof of a consistent source of income for at least the
                        past 90 days
                    </li>
                    <li>
                        To be 18 years of age or older and a citizen or
                        permanent resident of the United States
                    </li>
                    <li>To have a valid checking account in your name</li>
                    <li>
                        To have a valid email address, a current home phone
                        number (as well as a work phone number, if applicable)
                    </li>
                </ul>
            </Fragment>
        ),
    },
    {
        id: 3,
        question: 'How much can I borrow?',
        answer: (
            <p>
                You can apply for any amount between $100.00 and $1,500.00. The
                amount you are approved for will be determined from the
                information submitted on your application.
            </p>
        ),
    },
    {
        id: 4,
        question: 'How do I receive the money I borrow?',
        answer: (
            <p>
                After your installment loan is approved, the money is
                electronically deposited into your checking account and
                available the next business day.{' '}
                <strong>
                    <em>However</em>, if you would like to receive your money
                    instantly, you can pay an additional fee and have your funds
                    deposited to your checking account.
                </strong>{' '}
                For more information on <em>Same Day Funding</em>, click{' '}
                <a href="/same-day-funding">here</a>.
            </p>
        ),
    },
    {
        id: 5,
        question: 'Can I still get an installment loan if I have bad credit?',
        answer: (
            <p>
                Yes, you can! You don't need good credit to receive a loan
                &mdash; your regular source of income is your security. Today
                Cash does not utilize traditional credit bureaus in the same
                manner employed by other lenders. By completing and submitting
                the website application on this website, however, you
                acknowledge that you are applying for a loan. As part of our
                underwriting process, we verify applicant information through
                certain national databases including - but not limited to -
                Clarity, DataX and FactorTrust; we may pull your credit to
                determine your eligibility and ability to repay.
            </p>
        ),
    },
    {
        id: 6,
        question: 'How long does it take?',
        answer: (
            <p>
                As little as a few minutes to fill in the application and just a
                short phone call to verify application information.
            </p>
        ),
    },
    {
        id: 7,
        question: 'Do I need a fax machine or a printer?',
        answer: (
            <p>
                These items are not required. We may need to speak to you by
                phone to finalize your application process, and other details
                may be resolved via email, text message, or LiveChat on the
                occasions we may need additional banking and employment
                verification information. You will only need a printer in the
                event you wish to print out a paper copy of your loan agreement.
            </p>
        ),
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function FAQs() {
    return (
        <main className="bg-gray-100 font-inter">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-extrabold text-portmain-100 sm:text-4xl">
                        Frequently Asked Questions
                    </h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure
                                as="div"
                                key={faq.question}
                                className="pt-6"
                            >
                                {({ open }) => (
                                    <Fragment>
                                        <dt className="text-lg">
                                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-tdcblue-100">
                                                <span className="font-medium text-gray-900">
                                                    {faq.question}
                                                </span>
                                                <span className="ml-6 h-7 flex items-center">
                                                    <ChevronDownIcon
                                                        className={classNames(
                                                            open
                                                                ? '-rotate-180'
                                                                : 'rotate-0',
                                                            'h-6 w-6 transform'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel
                                            as="dd"
                                            className="mt-2 pr-12"
                                        >
                                            <p className="text-base text-gray-500">
                                                {faq.answer}
                                            </p>
                                        </Disclosure.Panel>
                                    </Fragment>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </main>
    );
}
