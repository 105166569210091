import { Fragment, h } from 'preact';
import { Popover, Transition } from '@headlessui/react';
import { useState } from 'preact/hooks';
import { Modal } from 'flowbite-react';
import {
    ChartBarIcon,
    CursorClickIcon,
    DocumentReportIcon,
    MenuIcon,
    RefreshIcon,
    ShieldCheckIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import logo from '../../../../images/tc_logo_nobg.png';
import sms_terms from 'docs/SMS Terms of Use.pdf';
import privacy from 'docs/TodayCash_Privacy_Policy.pdf';
import license from 'docs/License.pdf';

const navigation = {
    quick: [
        // {
        //     name: 'Apply Now',
        //     href: '/apply-now',
        // },
    ],
    resources: [
        { name: 'SMS Terms of Use', href: sms_terms, target: '_blank' },
        { name: 'Privacy Policy', href: privacy, target: '_blank' },
        { name: 'Terms of Use', href: '/terms-of-use', target: null },
        { name: 'Rates & Terms', href: '/rates-and-terms', target: null },
        { name: 'Data Collection', href: '/data-collection', target: null },
        { name: 'FAQ', href: '/faq', target: null },
        { name: 'Lender License', href: license, target: '_blank' },
    ],
};

export default function Footer() {
    const [show_message, set_show_message] = useState(false);
    return (
        <Popover className="relative shadow-md bg-gray-100 font-inter">
            <Modal
                show={show_message}
                onClose={() => set_show_message(false)}
                size="sm"
                popup
            >
                <Modal.Body>
                    <div style="text-align:center;">
                        <div style="display:flex;align-items:center;justify-content:center;">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-20 stroke-tdcblue-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                            </svg>
                        </div>
                        <h2 className="text-tdcblue-100 font-bold text-[20px]">You&#39;re leaving this site</h2>
                        <div style="margin-top:5px; margin-bottom:10px;">
                            <p className="text-gray-600">
                                Press continue to proceed and open a new window. Or press close to stay on this page.
                            </p>
                        </div>
                        <div className="flex space-x-4 justify-center">
                            <button
                                style="border-radius:5px;"
                                className="h-10 px-5 bg-gray-400 shadow-lg text-white hover:text-white hover:bg-gray-500"
                                onClick={() => {
                                    set_show_message(false);
                                }}>
                                    Close
                            </button>
                            <button
                                style="border-radius:5px;"
                                onClick={() => {
                                    set_show_message(false);
                                    window.open('https://onlinelendersalliance.org/look-for-the-ola-seal');
                                }}
                                className="z-50 h-10 px-5 bg-tdcblue-100 shadow-lg text-white hover:text-white hover:bg-[#033f99]"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <footer aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center py-6 lg:justify-start lg:space-x-10">
                        <div className="flex justify-start lg:flex-1">
                            <a className="w-[200px] h-[100px]" href="/">
                                <span className="sr-only">TodayCash</span>
                                <img
                                    className="w-auto pt-[30px]"
                                    src={logo}
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 lg:hidden">
                            <Popover.Button className="bg-portmain-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-tdcblue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <Popover.Group
                            as="nav"
                            className="hidden lg:flex space-x-10"
                        >
                            {navigation.resources.map((item) => (
                                <div key={item.name}>
                                    <a
                                        href={item.href}
                                        className="text-base text-portmain-100 hover:text-tdcblue-100"
                                        target={item.target}
                                    >
                                        {item.name}
                                    </a>
                                </div>
                            ))}
                        </Popover.Group>
                    </div>
                </div>
                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-50"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-white divide-y-2 divide-tdcblue-100">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <a href="/">
                                            <div className="flex items-center">
                                                <img
                                                    className="h-[50px] w-full"
                                                    src="/public/images/tc_logo_nobg.png"
                                                    alt=""
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-portmain-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-tdcblue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    {navigation.resources.map((item) => (
                                        <div key={item.name}>
                                            <a
                                                href={item.href}
                                                target={item.target}
                                                className="text-base text-portmain-100 hover:text-tdcblue-100"
                                            >
                                                {item.name}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
                <div className="max-w-7xl mx-auto text-justify pb-[3rem] px-4 sm:px-6 lg:px-8">
                    <p className="my-5 text-blue-900">
                        TodayCash.com is a website owned and operated by Loon
                        Lending, LLC d/b/a Today Cash ("Today Cash"), an
                        economic development arm of, instrumentality of, and a
                        limited liability company wholly-owned and controlled
                        by, the Menominee Indian Tribe of Wisconsin (the
                        "Tribe"), a federally-recognized sovereign American
                        Indian tribe. Today Cash adheres to federal consumer
                        finance laws and operates within the boundaries of the
                        Tribe's reservation. Funds borrowed from Today Cash are
                        governed by federal law and the laws of the Tribe, not
                        the law of the state where you reside. Nothing in this
                        communication should be construed as a waiver of
                        sovereign immunity, which is expressly preserved.
                    </p>
                    <p className="my-5 text-blue-900">
                        <span className="text-portmain-100">
                            Please note:
                        </span>{' '}
                        This is an expensive form of borrowing. Today Cash
                        installment loans are designed to assist you in meeting
                        your short-term financial needs and are not intended to
                        be a long-term financial solution. Examples of why our
                        services may be used include unexpected emergencies, car
                        repair bills, medical care or essential travel expenses.
                    </p>
                    <p className="my-5 text-blue-900">
                        Completion of our installment loan application in no way
                        guarantees that you will be approved for an installment
                        loan. Today Cash reviews your information in real-time
                        to determine whether you meet our lending criteria. You
                        acknowledge that by submitting and completing the loan
                        application that you are applying for a loan. We verify
                        loan applicant information through alternative credit
                        reporting agencies and may obtain your credit
                        information in order to determine your eligibility and
                        ability to repay.
                    </p>
                    <p className="my-5 text-blue-900">
                        This service is offered to you from the Tribe’s
                        reservation, regardless of where you may be situated or
                        access this site, and constitutes an offer for consumer
                        loans solely within the Tribe’s jurisdiction, not any
                        other state. The Tribe does not allow access or accept
                        applications for this service from residents of all
                        locations, and the service may or may not be available
                        at your location. The location from which the Tribe
                        chooses to allow access to its jurisdiction may change
                        from time to time without notice. If you choose to visit
                        the Tribe’s jurisdiction through the internet and access
                        services offered and regulated under the Tribe’s laws,
                        you must consent to the Tribe’s jurisdiction and agree
                        that Tribal law governs such services. Availability of
                        loans are subject to change at any time at the sole
                        discretion of Today Cash.
                    </p>
                    <p className="my-5 text-blue-900">
                        If you have additional questions, please call us at{' '}
                        <span className="text-portmain-100">
                            <a href="tel:8552162631">855.216.2631</a>
                        </span>{' '}
                        or email us at{' '}
                        <span className="text-portmain-100">
                            <a href="mailto:customerservice@TodayCash.com">CustomerService@TodayCash.com</a>
                        </span>
                        .
                    </p>
                    <div className="flex justify-center items-center">
                        <a
                            onClick={() =>
                                set_show_message(true)
                            }
                        >
                            <img src="https://stage.ola-memberseal.org/seal?seal=member&token=ER4EfOHI0uCAX4mKHrv/" />
                        </a>
                    </div>
                    <div className="mt-8 border-t border-tdcblue-100 pt-8 md:flex md:items-center md:justify-between">
                        <p className="mt-8 text-base text-portmain-100 md:mt-0 md:order-1">
                            &copy; 2023 Today Cash. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </Popover>
    );
}
