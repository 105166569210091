import { h, render, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import 'css/pages/hero.css';

export default function Hero() {
    const [switchToggled, setSwitchToggled] = useState(false);
    const [state, setState] = useState(0);
    const smallButton =
        'cursor-pointer px-4 xs:px-6 md:px-16 lg:px-8 xl:px-12 py-3 bg-tdcblue-100 text-white text-md font-medium rounded-md shadow-xl border border-transparent transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-portmain-700 hover:border-3 hover:border-portmain-700 hover:transition-color';
    const largeButton =
        'cursor-pointer w-full py-3 bg-tdcblue-100 text-white text-md font-medium rounded-md shadow-xl border border-transparent transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-portmain-700 hover:border-3 hover:border-portmain-700 hover:transition-color';
    function getSelectedValue() {
        const selectedValue = document.getElementById('location').value;
        setState(selectedValue);
    }

    // eslint-disable-next-line no-return-assign
    return (
        <div
            id="hero"
            className="relative w-full h-full bg-gray-100 bg-blend-multiply md:bg-no-repeat md:bg-center lg:bg-cover lg:bg-blend-normal lg:bg-left xl:bg-left-top 2xl:bg-center"
        >
            <main className="lg:relative font-heebo">
                <div
                    id="main_content"
                    className="flex justify-start items-center mx-auto max-w-7xl w-full pt-16 pb-10 text-center lg:pt-35 lg:pb-33 lg:text-left"
                >
                    <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                        <h1
                            id="title"
                            className="text-3xl text-center tracking-tight font-extrabold text-tdcblue-100 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl mt-[5rem]"
                        >
                            <span className="block xl:inline">Fast Cash </span>
                            <span className="block text-portmain-100 xl:inline font-inter">
                                {' '}
                                Quality Service
                            </span>
                        </h1>
                        <p className="mt-3 max-w-md mx-auto text-lg text-center text-tdcblue-100 sm:text-xl md:mt-5 md:max-w-3xl">
                            Get up to $1,500 by applying today! *
                        </p>
                        <div className="w-full h-50 p-5 md:h-96 mt-8 bg-white rounded-3xl md:p-10 lg:p-8 xl:p-10 shadow-2xl font-heebo">
                            <div>
                                <p className="text-center text-xl text-gray-500 font-semibold font-heebo">
                                    Get The Right Loan That You Need Today!
                                </p>
                            </div>
                            <div>
                                <form
                                    action="/apply-now"
                                    className="pt-5 sm:max-w-xl sm:mx-auto lg:mx-0"
                                    method="GET"
                                >
                                    {/* md:pt-10 */}
                                    <div className="font-heebo">
                                        <select
                                            id="states"
                                            name="states"
                                            onChange={getSelectedValue}
                                            className="mt-1 block w-full px-4 py-3 shadow-sm rounded-md border-gray-300 text-base text-gray-900 placeholder-gray-500 focus:border-portmain-100 focus:outline-none focus:ring-portmain-100"
                                            defaultValue="Select a state"
                                        >
                                            <option selected>
                                                Select a state
                                            </option>
                                            <option value="Alabama">
                                                Alabama
                                            </option>
                                            <option value="Arizona">
                                                Arizona
                                            </option>
                                            <option value="California">
                                                California
                                            </option>
                                            <option value="Colorado">
                                                Colorado
                                            </option>
                                            <option value="Delaware">
                                                Delaware
                                            </option>
                                            <option value="Florida">
                                                Florida
                                            </option>
                                            <option value="Georgia">
                                                Georgia
                                            </option>
                                            <option value="Hawaii">
                                                Hawaii
                                            </option>
                                            <option value="Idaho">Idaho</option>
                                            <option value="Indiana">
                                                Indiana
                                            </option>
                                            <option value="Iowa">Iowa</option>
                                            <option value="Kansas">
                                                Kansas
                                            </option>
                                            <option value="Kentucky">
                                                Kentucky
                                            </option>
                                            <option value="Louisiana">
                                                Louisiana
                                            </option>
                                            <option value="Michigan">
                                                Michigan
                                            </option>
                                            <option value="Mississippi">
                                                Mississippi
                                            </option>
                                            <option value="Missouri">
                                                Missouri
                                            </option>
                                            <option value="Montana">
                                                Montana
                                            </option>
                                            <option value="Nebraska">
                                                Nebraska
                                            </option>
                                            <option value="Nevada">
                                                Nevada
                                            </option>
                                            <option value="North Dakota">
                                                North Dakota
                                            </option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="Oklahoma">
                                                Oklahoma
                                            </option>
                                            <option value="Oregon">
                                                Oregon
                                            </option>
                                            <option value="Rhode Island">
                                                Rhode Island
                                            </option>
                                            <option value="South Dakota">
                                                South Dakota
                                            </option>
                                            <option value="Tennessee">
                                                Tennessee
                                            </option>
                                            <option value="Texas">Texas</option>
                                            <option value="Utah">Utah</option>
                                            <option value="Washington">
                                                Washington
                                            </option>
                                            <option value="Wyoming">
                                                Wyoming
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mt-3 sm:mt-3">
                                        <button
                                            type="submit"
                                            className="mt-2 mb-10 md:mt-5 w-full py-3 rounded-md shadow-sm bg-portmain-100 text-white text-lg font-medium border border-transparent transition ease-in-out delay-120 hover:border-3 hover:border-portmain-100 hover:text-portmain-100 hover:bg-white duration-300"
                                        >
                                            Apply Now
                                        </button>
                                    </div>
                                </form>
                                <div>
                                    <p className="w-full p-3 bg-tdcblue-100 text-center mt-1 text-white rounded">
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                className="w-4 h-4 text-white pr-2"
                                            />
                                        </span>
                                        Received Offer In Mail?{' '}
                                        <span>
                                            <a
                                                href="/apply-now"
                                                className="font-semibold text-lg text-white pl-1 visited:text-white transition ease-in-out delay-120 hover:no-underline"
                                            >
                                                Start Here
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="mt-8 text-sm text-tdcblue-100 sm:mt-8 font-heebo">
                            * Completion of our installment loan application in
                            no way guarantees that you will be approved for an
                            installment loan offer. The maximum loan amount for
                            first-time customers is $1,400.00.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}
