import { h, render, Fragment } from 'preact';

export default function CTA() {
    return (
        <div className="bg-white font-inter">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="bg-tdcblue-100 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                                <span className="block">Ready to Start?</span>
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-white">
                                Anything can happen. With a click of a button,{' '}
                                <span className="text-portmain-100 font-semibold">
                                    Today Cash
                                </span>{' '}
                                is committed to keeping you financially covered.
                            </p>
                            <form
                                action="apply-now"
                                method="GET"
                                className="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
                            >
                                <div className="min-w-0 flex-1">
                                    <label
                                        htmlFor="cta-email"
                                        className="sr-only"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        id="cta-email"
                                        type="email"
                                        name="email"
                                        required
                                        className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                    />
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-3">
                                    <button
                                        type="submit"
                                        className="block w-full rounded-md border border-transparent px-5 py-3 bg-portmain-100 text-base font-medium text-white shadow transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-portmain-100 hover:border-portmain-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="w-full h-full bg-gray-100">
                        <img
                            className="w-full h-[400px] object-cover"
                            src="/public/images/tc_family.jpg"
                            alt="Family"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
