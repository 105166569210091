import { Fragment, h } from 'preact';
import Footer from 'js/components/design/Footer.js';
import Header from 'js/components/design/Header.js';

const AccountLayout = ({ children }) => {
    return (
        <Fragment>
            <Header />
            {children}
            <Footer />
        </Fragment>
    );
};

export default AccountLayout;
