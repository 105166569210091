// Must be the first import
if (process.env.NODE_ENV === 'development') {
    // Must use require here as import statements are only allowed
    // to exist at top-level.
    require('preact/debug');
    require('preact/devtools');
}
import 'css/styles.css';
import { h, render, Fragment } from 'preact';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'js/components/User/AuthProvider.js';
import 'js/shared/logging.js';
import Routes from 'js/routes.js';
const React = require('react');
const ReactDOM = require('react-dom');

if (process.env.NODE_ENV !== 'production') {
    // const axe = require('@axe-core/react');
    // axe(React, ReactDOM, 1000);
}

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes />
            </Router>
        </AuthProvider>
    );
};

render(<App />, document.getElementById('app'));
